<template>
  <v-navigation-drawer class="leftNavBar" expand-on-hover rail theme="dark" permanent>
    <v-list density="compact" nav>
      <template v-for="(item, index) in mainLink" :key="index">
        <v-list-item 
          class="nav-item" 
          :active="$route.name == item.value ? true : false" 
          :prepend-icon="item.icon"
          :title="item.title" 
          :value="item.value" 
          @click="navigate(item.value)">
        </v-list-item>
        <v-divider></v-divider>
      </template>
    </v-list>
    <template v-slot:append>
      <v-divider></v-divider>
      <div class="pa-2" v-if="user">
        <v-list>
          <v-list-item class="nav-item" :active="$route.name == props.menuItems['profile'].value ? true : false"
            :prepend-avatar="user.avatar ? user.avatarLink : props.defaultAvatar" :title="user.lastName"
            :subtitle="user.email" @click="navigate(props.menuItems['profile'].value)"></v-list-item>
          <v-divider></v-divider>
          <v-list-item class="nav-item" :active="$route.name == props.menuItems['logout'].value ? true : false"
            :prepend-icon="props.menuItems['logout'].icon" :title="props.menuItems['logout'].title"
            :value="props.menuItems['logout'].value" @click="onLogOut"></v-list-item>
        </v-list>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script setup>
import { defineProps, computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute, useRouter } from 'vue-router'

import { useAuthStore } from '@/services/auth/store'
import hookAuth from "@/services/auth/hooks"

const { user } = storeToRefs(useAuthStore())

const { onLogOut } = hookAuth()

const props = defineProps({ menuItems: { type: Object }, defaultAvatar: { type: String, default: "../assets/avatars/defaultAvatar.svg" } })
const $route = useRoute()
const $router = useRouter()

const navigate = routeName => $router.push({ name: routeName })

const mainLink = computed(() => Object.values(props.menuItems).filter(link => link.is_in_main))
</script>

<style>
.leftNavBar {
  background: linear-gradient(56deg,
      rgb(154, 0, 91) 25%,
      rgba(154, 0, 91) 84%) !important;
  background-blend-mode: normal;
}

.leftNavBar .nav-item {
  color: #c5bbbb;
  cursor: pointer;
}

.leftNavBar .nav-item:hover {
  color: #ffffff;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.1);
}

.leftNavBar .nav-item.v-list-item--active {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.2);
}

.leftNavBar .nav-item.v-list-item--active i {
  color: #ffffff;
}

.leftNavBar .nav-item.v-list-item--active .v-list-item-title {
  font-weight: bold;
}

.leftNavBar .v-divider {
  margin: 0px !important;
}
</style>
