import { defineStore } from 'pinia'

import {
  apiGetGenders,
  apiGetLangs,
  apiGetCountries,
  apiGetCategory
} from './services/repository'


import {only} from '@/utils'

export const useGlobalStore = defineStore('global', {
  state: () => ({
    isLoading: false,
    genders: [],
    countries: [],
    categories: [],
    langs: [],
    actors: [],
    medias: []
  }),

  actions: {
    onAddActor (actor) {
      let actors = null
      if (Array.isArray(actor))
        actors = [...actor.map(tt => tt.run()), ...this.actors]
      else actors = [actor.run(), ...this.actors]

      this.actors = only(actors)
    }, //[onAddActor]

    onGetCategories () {
      apiGetCategory()
        .then(response => {
          const {
            data: {
              data: { data }
            }
          } = response

          this.categories = data
        })
        .catch(() => {})
        .finally(() => {})
    }, //[onGetCategories]

    onGetLangue () {
      apiGetLangs()
        .then(response => {
          const {
            data: {
              data: { data }
            }
          } = response

          this.langs = data
        })
        .catch(() => {})
        .finally(() => {})
    }, //[onGetLangue]

    onGetCountries () {
      apiGetCountries()
        .then(response => {
          const { data } = response
          this.countries = data
        })
        .catch(e => {
          e;
        })
        .finally(() => {})
    }, //[onGetCountries]

    onGetGenders () {
      apiGetGenders()
        .then(response => {
          const {
            data: {
              data: { data }
            }
          } = response
          this.genders = data
        })
        .catch(() => {})
        .finally(() => {})
    }, //[onGetGenders]

    onAddMedia (media) {
      this.medias = [...this.medias, media]
    }, //[onAddMedia]

    updateLoader (status) {
      this.isLoading = status
    }, //[updateLoader]

    resetMedia () {
      this.medias = []
      this.isLoading = false
      this.genders = []
      this.countries = []
      this.categories = []
      this.langs = []
      this.actors = []
      this.medias = []
    } //[resetMedia]
  },
  getters: {
    getLoader: state => state.isLoading,
    getGenders: state => state.genders,
    getActors: state => state.actors,
    getMedias: state => state.medias
  }
})
