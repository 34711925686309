import { auth } from '@/middlewares'

export const routes = [
  {
    path: '/private/home',
    component: () => import('./pages/HomeView.vue'),
    name: 'auth:home',
    meta: {
      title: 'Home',
      middleware: [auth]
    }
  },
  {
    path: '/',
    redirect: '/private/home'
  },
  {
    path: '/private/movies',
    component: () => import('./pages/MoviesView.vue'),
    name: 'auth:movies',
    meta: {
      title: 'Movie',
      middleware: [auth]
    }
  },
  {
    path: "/private/movies-details/:uuid",
    props: true,
    component: () => import('./pages/StatsMediaView.vue'),
    name: "movie:stats",
    meta: {
      title: 'Stats media',
      middleware: [auth]
    }
  },
  {
    path: '/private/series',
    component: () => import('./pages/SeriesView.vue'),
    name: 'auth:series',
    meta: {
      title: 'Series',
      middleware: [auth]
    }
  },
  {
    path: '/private/series/:uuid',
    props: true,
    component: () => import('./pages/SerieDetails.vue'),
    name: 'auth:series:detail',
    meta: {
      title: 'Series Detail',
      middleware: [auth]
    }
  },
  {
    path: '/private/actors',
    component: () => import('../actors/pages/actorsView.vue'),
    name: 'auth:actors',
    meta: {
      title: 'Mes Acteurs',
      middleware: [auth]
    }
  },
  {
    path: '/private/stats',
    component: () => import('./pages/StatsView.vue'),
    name: 'auth:stats',
    meta: {
      title: 'Stats',
      middleware: [auth]
    }
  }
]
