import { apiLogin, apiRegister, apiGetUser, apiLoginDiffuser, apiRefreshToken } from './repository'
import { Auth } from './entities'

const makeLogin = storage => {
  const make = data => {
    return new Auth({
      token: data.data.access_token,
      expire: data.data.expires_in,
      refresh: data.data.refresh_token
    })
  }
  return credentials => {
    return {
      api: async () => await storage(credentials),
      callback: returnApi => make(returnApi)
    }
  }
}


const makeRefreshToken = storage => {
  const make = data => {
    return new Auth({
      token: data.data.access_token,
      expire: data.data.expires_in,
      refresh: data.data.refresh_token
    })
  }

  // makerefresh est une fonctione qui prend les credentials et te fabrique un http + un call back. A toi d'utiliser
  return payload => {
    return {
      api: async () => await storage(payload),
      callback: apiDatas => make(apiDatas)
    }
  }
}

const makeLoginDiffuser = storage => {

  return credentials => {
    return {
      api: async () => await storage(credentials),
    }
  }
}

const makeRegister = storage => {
  return async data => {
    try {
      const request = await storage(data)
      return request
    } catch (error) {
      return error
    }
  }
}

const makeOnInit = storage => {
  const make = data => {
    const location = JSON.parse(window.localStorage.getItem('oz'))
    return new Auth({
      token: location.token,
      expire: location.expires,
      refresh: location.refresh,
      email: data.data.email,
      firstName: data.data.firstName,
      lastName: data.data.lastName,
      id: data.data.id,
    })
  }
  return credentials => {
    return {
      api: async () => await storage(credentials),
      callback: returnApi => make(returnApi)
    }
  }
}


export const commandLogin = makeLogin(apiLogin)
export const commandRefreshToken = makeRefreshToken(apiRefreshToken)
export const commandRegister = makeRegister(apiRegister)
export const commandOnInit = makeOnInit(apiGetUser)
export const commandLoginDiffuer = makeLoginDiffuser(apiLoginDiffuser)


