import { auth } from '@/middlewares'

export const routes = [
  {
    path: '/private/actors',
    component: () => import('./pages/actorsView.vue'),
    name: 'auth:actors',
    meta: {
      title: 'Mes Acteurs',
      middleware: [auth]
    }
  },
]
