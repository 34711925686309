import { createRouter, createWebHistory } from 'vue-router'

import { routes as routesAuthServices } from '@/services/auth'
import { routes as routesMoviesServices } from '@/services/movies'
import { routes as routesActorsServices } from '@/services/actors'


import { useAuthStore } from '@/services/auth/store'

import middlewarePipeline from '@/middlewares'

const routes = [
  ...routesAuthServices,
  ...routesMoviesServices,
  ...routesActorsServices,
  {
    path: '/401',
    name: '401',
    component: () => import('../views/errors/Error-401.vue'),
    props: true
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/errors/Error-404.vue'),
    props: true
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('../views/errors/Error-404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  
  if (!to.meta.middleware) {
    return next()
  }

  document.title = to.meta.title ? `OzStream - ${to.meta.title}` : 'OzStream'

  const store = useAuthStore()
  const middleware = to.meta.middleware
  const context = {
    to,
    from,
    next,
    store
  }

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  })
})

export default router
