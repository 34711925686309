import AxiosFunction from './axios'

export const urlGenerator = url => `${process.env.VUE_APP_API_URL}/${url}`

export const axiosGet = (url, data = null) => {
  return AxiosFunction.axiosGet(urlGenerator(url), data)
}

export const axiosPost = (url, data, formData = false) => {
  return AxiosFunction.axiosPost({
    url: urlGenerator(url),
    data,
    formData
  })
}

export const axiosPatch = (url, data, formData = false) => {
  return AxiosFunction.axiosPatch({
    url: urlGenerator(url),
    data,
    formData
  })
}

export const axiosDelete = (url, data) => {
  return AxiosFunction.axiosDelete(urlGenerator(url), data)
}
