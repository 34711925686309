<template>
    <template v-if="loading == true">
        <div class="loader-container">
            <div class="position-absolute top-50 start-50 translate-middle">
                <div class="d-flex justify-content-center">
                    <div class="spinner-border  text-light" style="width: 3rem; height: 3rem;" role="status">
                        <span class="visually-hidden">Loading - Chargement...</span>
                    </div>
                </div>
                <h3 class="text-center  text-light">Patientez !</h3>
                <span class="position-absolute top-50 start-50 translate-middle mt-5">{{ strDot }}</span>
            </div>
        </div>
    </template>
</template>
<script setup>
import { ref, defineProps } from 'vue';

const props = defineProps({
    loading: {
        type: Boolean,
        default: false
    }
})
const dotValue = ref(0);
const strDot = ref('.');

function load() {
    if (props.loading) {
        setTimeout(() => {
            dotValue.value++;
            if (dotValue.value > 3) {
                dotValue.value = 0;
            }
            strDot.value = dotValue.value == 1 ? '.' : (dotValue.value == 2 ? '..' : (dotValue.value == 3 ? '...' : ''));
            load();
        }, 500);
    }
}
load();

</script>
<style>
.loader-container {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}
</style>
