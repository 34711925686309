export class Auth {
  constructor (params) {
    this.expire = params.expire
    this.token = params.token
    this.refresh = params.refresh
    this.firstName = params.firstName ? params.firstName : ''
    this.lastName = params.lastName ? params.lastName : ''
    this.email = params.email ? params.email : ''
    this.diffuser = {
      tokenSpace: null
    }
    this.id = params.id
  }

  setDiffuser (data) {
    this.diffuser = data
  }
  
}
