import Swal from 'sweetalert2'

export const pipeline = () => {
  const events = []
  return {
    add: (callback, data = null) => {
      const event = () => {
        return {
          process: () => callback(data)
        }
      }
      events.push(event())
    },
    run: async () => {
      let status = false
      for (let event of events) {
        const response = await event.process()
        if (!response) {
          status = response
          break
        }
      }
      return status
    }
  }
}

export const location = JSON.parse(window.localStorage.getItem('oz'))

export const random = (length = 20) => {
  var result = ''
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZ!@$%^&*()-_+=.<>[]0123456789abcdefghijklmnopqrstuvwxyz'
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

const toastParser = data => {

  let params = {
    icon: data.icon ? data.icon : 'success',
    title: data.title ? data.title : 'Succès',
    timer: data.timer ? data.timer : 3000,
    showConfirmButton: false
  }

  if (data.html) params.html = data.html
  else
    params.text = data.message
      ? data.message
      : 'Operation effectuee avec success'

  if (data.confirmButtonColor) {
    params.confirmButtonColor = data.confirmButtonColor
    params.confirmButtonText = data.confirmButtonText
    params.showConfirmButton = true
  }

  Swal.fire({ ...params })
}

export const toast = toastParser

export const errorParser = ({response}, toastParams) => {
    
  if (response != undefined && 422 == response.status) {
    const renders = Object.values(response.data.data.error).flat()
    const html = `<ul>${renders}</ul>`
    toastParser({ ...toastParams, timer: 10000, html: html })
  } else {
    toastParser(toastParams)
  }

}

export const only = (array) => array.filter((obj, index) => {
  return index === array.findIndex((obj2) => obj.id === obj2.id);
});