import { guest, auth } from '@/middlewares'

export const routes = [
  {
    path: '/auth/login',
    component: () => import('./pages/LoginView.vue'),
    name: 'auth:login',
    meta: {
      title: 'Login',
      middleware: [guest]
    }
  },
  {
    path: '/auth/profile',
    component: () => import('./pages/ProfileView.vue'),
    name: 'auth:profile',
    meta: {
      title: 'Profile',
      middleware: [auth]
    }
  },
  {
    path: '/auth/register',
    component: () => import('./pages/RegisterView.vue'),
    name: 'auth:register',
    meta: {
      title: 'Register',
      middleware: [guest]
    }
  }
]
