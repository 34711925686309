import { axiosPost, axiosGet } from "@/http";

export const apiLogin = (data) => {
  return axiosPost("login", data);
};

export const apiRegister = (data) => {
  return axiosPost("login", data);
};

export const apiLoginDiffuser = (data) => {
  return axiosPost("space/diffuser/login", data);
};

export const apiGetUser = (data) => {
  return axiosGet("getuser", data);
};

export const apiDisconnect = () => {
  return true
};

export const apiRefreshToken = payload => {
  return axiosPost('refresh', payload)
}