import { axiosGet } from '@/http'

export const apiGetGenders = () => {
  return axiosGet('gender/only')
} //[apiGetGenders]

export const apiGetActors = data => {
  return axiosGet('actor', data)
} //[apiGetActors]

export const apiGetCategory = data => {
  return axiosGet('category', data)
} //[apiGetCategory]

export const apiGetCountries = data => {
  return axiosGet('all/countries', data)
} //[apiGetCountries]

apiGetCountries()

export const apiGetLangs = data => {
  return axiosGet('language', data)
} //[apiGetLangs]
